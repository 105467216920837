import * as client_hooks from '../../../../_commons/hooks-client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95')
];

export const server_loads = [0,2,4,5,8,14,9,11,12];

export const dictionary = {
		"/(landing)": [~92,[13]],
		"/(app)/campaigns": [~15,[2,4],[3]],
		"/(app)/campaigns/[campaignId]": [~16,[2,4],[3]],
		"/(landing)/cgu": [~93,[13]],
		"/(app)/clients": [~17,[2],[3]],
		"/(app)/clients/all": [40,[2],[3]],
		"/(app)/clients/import": [41,[2],[3]],
		"/(app)/clients/import/[batchId=guid]": [~42,[2],[3]],
		"/(app)/clients/newsletter": [~43,[2],[3]],
		"/(app)/clients/ongoing": [44,[2],[3]],
		"/(app)/clients/[companyId=guid]/aides": [~19,[2,5],[3]],
		"/(app)/clients/[companyId=guid]/aides/[slug]": [~20,[2,5,6],[3]],
		"/(app)/clients/[companyId=guid]/audit/[requestId]": [~21,[2,5],[3]],
		"/(app)/clients/[companyId=guid]/files": [~22,[2,5],[3]],
		"/(app)/clients/[companyId=guid]/newsletters/[...newsletterId]/results/[newsletterResultId]": [~24,[2,5],[3]],
		"/(app)/clients/[companyId=guid]/newsletters/[...newsletterId]": [~23,[2,5],[3]],
		"/(app)/clients/[companyId=guid]/notes": [~25,[2,5],[3]],
		"/(app)/clients/[companyId=guid]/potential": [~26,[2,5],[3]],
		"/(app)/clients/[companyId=guid]/reports": [27,[2,5],[3]],
		"/(app)/clients/[companyId=guid]/reports/roadmap/[...roadmapId]/aide/[slug]": [~31,[2,5],[3]],
		"/(app)/clients/[companyId=guid]/reports/roadmap/[...roadmapId]/review": [~32,[2,5],[3]],
		"/(app)/clients/[companyId=guid]/reports/roadmap/[...roadmapId]/settings": [~33,[2,5],[3]],
		"/(app)/clients/[companyId=guid]/reports/roadmap/[...roadmapId]": [~30,[2,5],[3]],
		"/(app)/clients/[companyId=guid]/reports/[requestId]": [~28,[2,5],[3]],
		"/(app)/clients/[companyId=guid]/reports/[requestId]/settings": [~29,[2,5],[3]],
		"/(app)/clients/[companyId=guid]/settings": [~34,[2,5,7],[3]],
		"/(app)/clients/[companyId=guid]/settings/billing": [~35,[2,5,7],[3]],
		"/(app)/clients/[companyId=guid]/settings/company": [36,[2,5,7],[3]],
		"/(app)/clients/[companyId=guid]/settings/experts": [37,[2,5,7],[3]],
		"/(app)/clients/[companyId=guid]/settings/team": [~38,[2,5,7],[3]],
		"/(app)/clients/[companyId=guid]/subscribe": [~39,[2,5],[3]],
		"/(app)/clients/[companyId=guid]/[...requestId]": [~18,[2,5],[3]],
		"/(app)/dashboard": [~45,[2],[3]],
		"/(auth)/email/verify": [~86],
		"/(app)/how-to-use": [~46,[2],[3]],
		"/(auth)/info": [~87],
		"/(app)/newsletters": [47,[2,8],[3]],
		"/(app)/newsletters/collective": [~48,[2,8],[3]],
		"/(app)/newsletters/collective/[newsletterId]": [~49,[2,8],[3]],
		"/(app)/newsletters/collective/[newsletterId]/clients": [52,[2,8],[3]],
		"/(app)/newsletters/collective/[newsletterId]/[newsletterTimestamp]": [~50,[2,8],[3]],
		"/(app)/newsletters/collective/[newsletterId]/[newsletterTimestamp]/[newsletterResultId]": [~51,[2,8],[3]],
		"/(app)/newsletters/firm": [~53,[2,8],[3]],
		"/(app)/newsletters/firm/create": [57,[2,8],[3]],
		"/(app)/newsletters/firm/[newsletterId]": [~54,[2,8],[3]],
		"/(app)/newsletters/firm/[newsletterId]/[newsletterResultId]": [~55,[2,8],[3]],
		"/(app)/newsletters/firm/[newsletterId]/[newsletterResultId]/grants/[slug]": [~56,[2,8],[3]],
		"/(onboarding)/onboarding": [~95,[14]],
		"/(auth)/password/forgot": [88],
		"/(auth)/password/reset": [89],
		"/(app)/prospects": [~58,[2,9],[3]],
		"/(app)/prospects/contacts": [59,[2,9],[3]],
		"/(app)/prospects/messages/[...messageId]": [~60,[2,9],[3]],
		"/(app)/prospects/missions": [~61,[2,9],[3]],
		"/(app)/prospects/new-offer": [~62,[2,9],[3]],
		"/(app)/prospects/opportunities": [~63,[2,9],[3]],
		"/(app)/prospects/opportunities/[prospectAdId=guid]": [~64,[2,9],[3]],
		"/(app)/prospects/quotes/[prospectAdContactId]": [~65,[2,9],[3]],
		"/(app)/prospects/quotes/[prospectAdContactId]/view": [~66,[2,9],[3]],
		"/(app)/settings": [~67,[2,10],[3]],
		"/(app)/settings/firm": [~68,[2,10,11],[3]],
		"/(app)/settings/firm/members": [69,[2,10,11],[3]],
		"/(app)/settings/firm/profile": [~70,[2,10,11],[3]],
		"/(app)/settings/firm/roles": [71,[2,10,11],[3]],
		"/(app)/settings/firm/subscription": [~72,[2,10,11,12],[3]],
		"/(app)/settings/firm/subscription/billing": [73,[2,10,11,12],[3]],
		"/(app)/settings/firm/subscription/billing/invoices": [~74,[2,10,11,12],[3]],
		"/(app)/settings/firm/subscription/billing/methods": [~75,[2,10,11,12],[3]],
		"/(app)/settings/firm/subscription/billing/payment-needed": [76,[2,10,11,12],[3]],
		"/(app)/settings/firm/subscription/billing/preferences": [~77,[2,10,11,12],[3]],
		"/(app)/settings/firm/subscription/buy-units": [~78,[2,10,11,12],[3]],
		"/(app)/settings/firm/subscription/units": [79,[2,10,11,12],[3]],
		"/(app)/settings/firm/visual-settings": [~80,[2,10,11],[3]],
		"/(app)/settings/preferences": [~81,[2,10],[3]],
		"/(app)/settings/profile": [82,[2,10],[3]],
		"/(app)/settings/profile/[expertId]": [~83,[2,10],[3]],
		"/(app)/settings/security": [84,[2,10],[3]],
		"/(auth)/signin": [~90],
		"/(auth)/signup": [~91],
		"/(app)/subscribe": [~85,[2],[3]],
		"/(landing)/tarifs": [~94,[13]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';